<template>
  <section class="choose-usualLive-router">
    <div class="search-component">
      <div class="title">数据筛选</div>
      <div class="header-container">
        <div class="item">
          <div class="text">年度：</div>
          <el-date-picker
            v-model="table.query.jhksnd"
            value-format="yyyy"
            type="year"
            size="small"
            placeholder="请选择年度"
            @change="changeNd"
          ></el-date-picker>
        </div>
        <div class="item">
          <div class="text">快速搜索：</div>
          <el-input v-model="table.query.keyword" placeholder="请输入关键词" size="small"></el-input>
        </div>
        <el-button type="primary" class="keyword" size="small" @click="search">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="main">
      <div class="main-header">
        <div class="router2-name">常住居民列表</div>
        <div class="btn">
          <!-- <el-button type="primary" size="small">分类统计</el-button> -->
          <!-- <el-button size="small" @click="importFile" v-auth="'import_ss'" v-if="auth.import_ss">批量导入</el-button> -->
          <el-button size="small" @click="exportExcel">导出EXCEL</el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          v-loading="table.loading"
          :data="table.data"
          row-key="id"
          fit
          height="100%"
          :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" :index="indexMath" ></el-table-column>
          <el-table-column prop="nd" label="年度"></el-table-column>
          <el-table-column prop="xrdw_name" label="选任单位"></el-table-column>
          <el-table-column prop="jjzrs" label="计划总人数"></el-table-column>
          <el-table-column prop="sjcxjhrs" label="随机抽选计划人数"></el-table-column>
          <el-table-column prop="czjmdrrs" label="常住居民导入人数"></el-table-column>
          <el-table-column prop="ysjcxrs" label="已随机抽选人数">
            <template slot-scope="scope">
              <span class="blue" @click="peoplelist(scope.row)">{{scope.row.ysjcxrs}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total"
      ></el-pagination>
    </div>
    <peoplelist ref="peoplelist"></peoplelist>
  </section>
</template>

<script>
import UsualLive from '@/api/choose/usualLive';
import peoplelist from './peoplelist.vue';
export default {
  // 常住居民名单
  name: 'usualLive',
  props: {},
  components: { peoplelist },
  data() {
    return {
      table: {
        data: [],
        query: {
          keyword: '',
          page: 1,
          size: 10,
          jhksnd: new Date().getFullYear().toString(),
        },
        loading: false,
        total: 0,
      },
      title: '随机抽取',
      Dictionary: {},
      auth: {
        import_ss: true,
        list_ss: true,
        selectlist_ss: true,
      },
    };
  },
  methods: {
    peoplelist(data) {
      this.$refs.peoplelist.toggle(true, { xrjh: data.xrjh_id, keyword: this.table.query.keyword });
    },
    exportExcel() {
      this.$store.commit('changeLoading', {
        loading: true,
        title: '正在导出excel,由于数据可能较多，请稍后',
        color: 'rgba(255,255,255,0.8)',
      });
      let { total } = this.table;
      let { keyword, jhksnd } = this.table.query;
      UsualLive.list_ss({
        keyword,
        jhksnd,
        page: 1,
        size: total,
      }).then((res) => {
        let cou = [
          { label: '序号', key: 'index', length: 100 },
          { label: '年度', key: 'nd', length: 100 },
          { label: '选任单位', key: 'xrdw_name', length: 150 },
          { label: '计划总人数', key: 'jjzrs', length: 100 },
          { label: '随机抽选计划人数', key: 'sjcxjhrs', length: 120 },
          { label: '常住居民导入人数', key: 'czjmdrrs', length: 120 },
          { label: '以抽选人数', key: 'ysjcxrs', length: 100 },
        ]
        // this.$exportExcel({
        //   name: `常住居民信息(${res.total}条)`,
        //   data: res.items,
        //   header: [
        //     { label: '序号', key: 'index', length: 100 },
        //     { label: '年度', key: 'nd', length: 100 },
        //     { label: '选任单位', key: 'xrdw_name', length: 150 },
        //     { label: '计划总人数', key: 'jjzrs', length: 100 },
        //     { label: '随机抽选计划人数', key: 'sjcxjhrs', length: 120 },
        //     { label: '常住居民导入人数', key: 'czjmdrrs', length: 120 },
        //     { label: '以抽选人数', key: 'ysjcxrs', length: 100 },
        //   ],
        // });
        this.$exportExcel(cou,res.items,`常住居民列表(${res.total}条)`)
      });
    }, // 修改年度
    changeNd() {
      if (this.table.query.jhksnd) {
        return this.getList();
      }
    },
    importFile() {},
    indexMath(index) {
      return index + (this.table.query.page - 1) * this.table.query.size + 1;
    },
    changeKeyWord(keyword) {
      this.table.query.keyword = keyword;
    },
    // 每页条数变化
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    reset() {
      this.table.query = {
        page: 1,
        size: 10,
        keyword: '',
        jhksnd: new Date().getFullYear().toString(),
      };
      return this.getList();
    },
    search() {
      return this.getList();
    },
    getList() {
      this.table.loading = true;
      UsualLive.list_ss(this.table.query)
        .then((res) => {
          this.table.data = res.items;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    return this.getList();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.choose-usualLive-router {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .search-component {
    background-color: #fff;
    margin-bottom: 20px;
    .title {
      color: $h4;
      border-bottom: 1px solid $border1;
      padding: 15px 30px;
      font-weight: bold;
    }
    .header-container {
      padding: 15px 30px;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
      }
      .keyword {
        margin-left: 20px;
      }
      .text {
        min-width: 70px;
        font-size: 14px;
        color: #666666;
      }
      .el-input {
        width: 224px;
        margin-right: 10px;
      }
    }
  }
  .main {
    background-color: #fff;
    box-sizing: border-box;
    flex: 1;
    min-height: 0;
    border-bottom: 1px solid #ccc;
    position: relative;
    display: flex;
    flex-direction: column;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .router2-name {
        color: $h4;
        padding: 15px 30px;
        font-weight: bold;
      }
      .el-button {
        margin-right: 20px;
      }
    }

    .table {
      padding-left: 30px;
      flex: 1;
      box-sizing: border-box;
      position: relative;
      padding-bottom: 50px;
      .el-table {
        width: calc(100% - 20px);
        border: 1px solid rgba(232, 232, 232, 1);
        border-bottom: none;
        .header-bgc {
          background-color: $border4;
        }
      }
    }
    .el-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
