import { tongke } from '@/axios'
class UsualLive {
  /**
   * @functionName import
   * @param { Object }  params
   * @param { Object }  params.data_key
   * @param { Object }  params.year  //年报
   * @Description   // 常住居民导入
   * @return { Object }
   */
  import(params) {
    return tongke.post('/quxian/czjm/import', params)
  }

  importZgsc(params){
    return tongke.post('/sifasuo/hxr/import', params)
  }
  /**
   * @functionName import
   * @param { Object }  params
   * @param { Object }  params.data_key
   * @Description   // 常住居民导入
   * @return { Object }
   */
  import_ss(params) {
    return tongke.post('/shengshi/czjm/import', params)
  }

  /**
   * @functionName selectlist_ss
   * @param { Object }  params.page
   * @param { Object }  params.size
   * @param { Object }  params.srjh  选任计划id
   * @param { Object }  params.keyword 关键词
   * @Description 省市-已抽选人员
   * @return { Object }
   */
  selectlist_ss(params) {
    return tongke.get('/shengshi/ycxczjmlb', { params })
  }

  /**
   * @functionName list
   * @param { Object }  params
   * @param { Object }  params.page
   * @param { Object }  params.size
   * @param { Object }  params.keyword //关键词
   * @Description   // 常住居民列表
   * @return { Object }
   */
  list(params) {
    return tongke.get('/quxian/czjm/info', { params })
  }
  /**
   * @functionName list_ss
   * @param { Object }  params
   * @param { Object }  params.page
   * @param { Object }  params.size
   * @param { Object }  params.keyword //关键词
   * @Description   // 常住居民列表
   * @return { Object }
   */
  list_ss(params) {
    return tongke.get('/shegnshi/czjm/info', { params })
  }
}
export default new UsualLive()
